import { Button, Alert } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import dataUriToBlob from "data-uri-to-blob";
import { useTranslation } from "react-i18next";

const Camera = ({ data }) => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [visible, setVisible] = useState(null);

  const { t } = useTranslation();

  const capturePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setVisible(imageSrc);

    const blob = dataUriToBlob(imageSrc);

    const imageFile = new File([blob], "captured.jpg", { type: "image/jpeg" });

    setCapturedImage(imageFile);
  };

  const removePhoto = () => {
    setVisible(null);
  };

  const finish = () => {
    data(capturedImage);
  };

  return (
    <div style={{ lineHeight: 2 }}>
      {/* <h1>Camera App with react-webcam</h1> */}
      {visible ? (
        <div>
          <img style={{ borderRadius: 10 }} src={visible} alt="Captured" />
          <Button
            onClick={removePhoto}
            Button
            type="default"
            htmlType="submit"
            style={{ width: "100%", height: "42px", marginBottom: 20 }}
          >
            {t("TirarNovamente")}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: "42px" }}
            onClick={(e) => finish()}
          >
            {t("Proximo")}
          </Button>
        </div>
      ) : (
        <Fragment>
          {/* {webcamRef?.current != null ? (
            <></>
          ) : (
            <Alert
              message={`Erro ao Abrir a Câmera. Certifique-se de que nenhum outro aplicativo ou programa esteja usando a câmera neste momento.`}
              type="error "
            />
          )} */}
          <Webcam
            audio={false}
            mirrored={true}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: "user", // or 'user' for front camera
            }}
          />
          <Button
            onClick={capturePhoto}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: "42px" }}
          >
            {t("TirarFoto")}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default Camera;
