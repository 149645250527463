import React, { useEffect, useState } from "react";
import { Button, Divider, message, Steps, theme } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
// import DataDocument from "./DataDocument";
import DateFormPerson from "./dateForm";
import MultiUpload from "../../components/multiUploads";
import Description from "./description";
import RegisterPersonDone from "./done";
import { useTranslation } from "react-i18next";

const RegisterPerson = ({ user, setcloseDrawer }) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [personData, setPersonData] = useState();
  const [picturesData, setPicturesData] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (personData != undefined) {
      setCurrent(1);
    }
  }, [personData]);

  useEffect(() => {
    console.log("dataaa", picturesData);

    if (picturesData != undefined) {
      setCurrent(2);
    }
  }, [picturesData]);

  useEffect(() => {
    console.log("dataaa", description);

    if (description != undefined) {
      setCurrent(3);
    }
  }, [description]);

  const steps = [
    {
      title: t("Dados"),
      content: <DateFormPerson data={setPersonData} />,
    },
    {
      title: t("Fotos"),
      content: <MultiUpload data={setPicturesData} />,
    },
    {
      title: t("Descricao"),
      content: <Description data={setDescription} />,
    },
    {
      title: t("Registro"),
      content: (
        <RegisterPersonDone
          person={personData}
          pictures={picturesData}
          description={description}
          setLoad={setLoader}
          user={user}
          setcloseDrawer={setcloseDrawer}
          setCurrent={setCurrent}
        />
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 20,
  };

  return (
    <div style={{ margin: "auto", width: "100%" }}>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {/* {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Proximo
          </Button>
        )} */}
        {/* {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )} */}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
            disabled={loader}
          >
            Anterior
          </Button>
        )}
      </div>
    </div>
  );
};
export default RegisterPerson;
