import React, { useEffect, useState } from "react";
import { Button, Modal, Result } from "antd";
import Title from "antd/es/typography/Title";
const SucessFace = ({ message, openModal, closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(openModal);
  }, [openModal]);

  const handleOk = () => {
    setIsModalOpen(false);
    closeModal(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    closeModal(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ hidden: true, disabled: true, unselectable: true }}
      >
        <div style={{ textAlign: "center" }}>
          <Result
            status="success"
            title="Successfully!"
            subTitle={message}

            // extra={[
            //   <Button type="primary" key="console">
            //     Go Console
            //   </Button>,
            // ]}
          />
        </div>
      </Modal>
    </>
  );
};
export default SucessFace;
