import React, { useEffect, useState } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import api from "../../axio/http";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const RegisterPersonDone = ({
  person,
  pictures,
  description,
  setLoad,
  user,
  setcloseDrawer,
  setCurrent,
}) => {
  const [loader, setLoader] = useState(null);

  const { t } = useTranslation();


  const Register = async () => {
    setLoader("register");
    setLoad(true);

    const formData = new FormData();
    formData.append("userId", user.id);
    formData.append("name", person.username);
    formData.append("date", person.date); // Preencha com o valor correto
    formData.append("gender", person.gender); // Preencha com o valor correto
    formData.append("eyes", person.eyes); // Preencha com o valor correto
    formData.append("height", person.height); // Preencha com o valor correto
    for (let i = 0; i < pictures.length; i++) {
      const element = pictures[i];

      formData.append("image", element.originFileObj);
    } // Preencha com o valor correto
    formData.append("weight", person.weight); // Preencha com o valor correto
    formData.append("description", description.description); // Preencha com o valor correto
    formData.append("lastTimesee", description.lastTimesee); // Preencha com o valor correto
    formData.append("location", description.location); // Preencha com o valor correto

    try {
      const response = await api.post("createPeople", formData);

      console.log(response.data);

      setLoader("sucess");
      setLoad(true);
    } catch (error) {
      console.log(error);

      setLoader("error");
      setLoad(false);
    }
  };

  const closeModal = () => {
    setcloseDrawer(false);
    setTimeout(() => {
      setCurrent(0);
    }, 2000);
  };

  return loader == "sucess" ? (
    <Result
      icon={<SmileOutlined />}
      title={t("PersonRegistersuccess")}
      extra={
        <Button type="primary" onClick={() => closeModal()}>
          {t("Terminar")}
        </Button>
      }
      style={{ lineHeight: 0 }}
    />
  ) : loader == "error" ? (
    <Result
      status="warning"
      title={t("errorRegisterUser")}
      extra={
        <Button type="primary" key="console" onClick={() => Register()}>
          {t("TenteNovamente")}
        </Button>
      }
      style={{ lineHeight: 0 }}
    />
  ) : loader == "register" ? (
    <Spin size="large" />
  ) : (
    <>
      <Result
        status="success"
        title={t("OperacaoExecutada")}
        extra={
          <Button type="primary" key="console" onClick={() => Register()}>
            {t("ConcluirRegistro")}
          </Button>
        }
        style={{ lineHeight: 0 }}
      />
    </>
  );
};
export default RegisterPersonDone;
