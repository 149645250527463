import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Card } from "antd";
import PersonDetails from "./PersonDetails";
import { getPeopleDetails } from "../../request/People";
import { useTranslation } from "react-i18next";
const { Meta } = Card;

// import './css.css'

const Saved = ({ openDrawer, setcloseDrawer, data }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  const onChange = async (select) => {
    setOpen(false);

    setOpenDetails(!openDetails);

    const response = await getPeopleDetails(select);

    console.log(response);

    setSelected(response);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title={t("Emandamento")}
        placement="right"
        onClose={onClose}
        open={open}
        width={"305px"}
      >
        <PersonDetails
          openDrawer={openDetails}
          setcloseDrawer={setOpenDetails}
          data={selected}
          setSelected={setSelected}
        />
        {data.map((queue) => {
          return (
            <Card
              hoverable
              style={{
                width: 240,
                marginBottom: 30,
              }}
              cover={<img alt="example" src={queue?.People?.image} />}
              onClick={() => onChange(queue?.People?.id)}
            >
              <Meta
                title={queue?.People?.name}
                description={`${t("Estado")}: ${
                  queue?.status == true ? "Em analise" : "Finalizado"
                }`}
              />
            </Card>
          );
        })}
      </Drawer>
    </>
  );
};
export default Saved;
