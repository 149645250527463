import React, { Fragment, useEffect, useState } from "react";
import { Button, Descriptions, Drawer, Image, Space, Skeleton } from "antd";
import RegisterPerson from "../../pages/Person/register";
import {
  CommentOutlined,
  MoreOutlined,
  SwapOutlined,
  HeartOutlined,
  MessageOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import MatchFace from "../modal/matchFace";
import { Likes } from "../../request/Likes";
import { Timeline } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { formatarDataHora, obterData } from "../../utils/formatarDataeHora";
const { Search } = Input;

// import './css.css'

const PersonDetails = ({ openDrawer, setcloseDrawer, data }) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [likes, setLikes] = useState(data?.like);
  const [message, setMessage] = useState(false);
  const { t } = useTranslation();

  const LikeAction = async () => {
    setLikes(likes + 1);

    const lik = await Likes(data?.User?.id, data.id);

    console.log(lik);
  };

  const infoAction = async () => {
    setMessage(false);
  };

  useEffect(() => {
    setOpen(openDrawer);
    setLikes(data?.like);
  }, [openDrawer, data?.like]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
    setActive(true);
  };

  useEffect(() => {
    if (data != undefined) {
      setActive(false);
    }
  }, [data]);

  const items = [
    {
      key: "1",
      label: t("NomeCompleto"),
      children: data?.name,
    },
    {
      key: "3",
      label: t("DataDeNascimento"),
      children: obterData(data?.date),
    },
    {
      key: "4",
      label: t("Cordosolhos"),
      children: data?.eyesColor,
    },
    {
      key: "5",
      label: t("Altura"),
      children: data?.height,
    },
    {
      key: "6",
      label: t("Peso"),
      children: data?.weight,
    },
    {
      key: "7",
      label: t("Dataehorariodaultimavezquefoivista"),
      children: formatarDataHora(data?.lastTimeSee),
    },
    {
      key: "8",
      label: t("Enderecooulocalizacaoexataondeapessoafoivistapelaultimavez"),
      children: data?.lastTimeLocation,
    },
    {
      key: "9",
      label: t("NomedoParente"),
      children: data?.User?.name,
    },
    {
      key: "10",
      label: t("ContactoDoParente"),
      children: data?.User?.phoneNumber,
    },
    {
      key: "11",
      label: t("Descricao"),
      children: data?.description,
    },
  ];

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <MatchFace
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={data?.id}
      />
      <Drawer
        title="Informações pessoais da pessoa desaparecida"
        placement="bottom"
        onClose={onClose}
        open={open}
        height={"70%"}
      >
        {active ? (
          <>
            <Skeleton active={active} />
            <Skeleton active={active} />
            <Skeleton active={active} />
          </>
        ) : (
          <Fragment>
            {message != true ? (
              <Fragment>
                <Space wrap style={{ marginBottom: 20 }}>
                  {data?.galery.map((gallery) => {
                    return (
                      <Image.PreviewGroup
                      // items={[
                      //   "https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp",
                      // ]}
                      >
                        <Image
                          width={180}
                          height={200}
                          style={{ objectFit: "cover" }}
                          src={gallery?.image}
                        />
                      </Image.PreviewGroup>
                    );
                  })}
                </Space>
                <Descriptions title="Info" items={items} layout="vertical"/>
              </Fragment>
            ) : (
              <>
                <Timeline
                  mode="left"
                  style={{ width: "96.8%" }}
                  items={[
                    {
                      dot: (
                        <img
                          src={data?.galery[0]?.image}
                          style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ),
                      children:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    },
                    {
                      dot: (
                        <img
                          src={data?.galery[1]?.image}
                          style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ),
                      children:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    },

                    {
                      dot: (
                        <img
                          src={data?.galery[0]?.image}
                          style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ),
                      children:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                    },

                    {
                      dot: (
                        <img
                          src={data?.galery[1]?.image}
                          style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ),
                      children:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of.",
                    },
                  ]}
                />

                <Space.Compact
                  style={{
                    width: "30%",
                    margin: "auto",
                    position: "absolute",
                    bottom: "23px",
                    left: "35%",
                  }}
                >
                  <Input placeholder="Escreva um comentario...." />
                  <Button type="primary">Comentar</Button>
                </Space.Compact>
              </>
            )}
            <FloatButton.Group
              trigger="click"
              type="primary"
              style={{
                right: 24,
              }}
              icon={<MoreOutlined />}
            >
              {message != false ? (
                <FloatButton
                  icon={<InfoCircleOutlined />}
                  onClick={() => infoAction()}
                />
              ) : (
                <></>
              )}
              <FloatButton
                badge={{
                  count: likes,
                  style: { transform: "translate(0px, -5px)", fontSize: 10 },
                }}
                icon={<HeartOutlined />}
                onClick={() => LikeAction()}
              />
              {/* <FloatButton
                badge={{
                  count: "5k",
                  style: { transform: "translate(0px, -5px)", fontSize: 10 },
                }}
                icon={<MessageOutlined />}
                type={message != true ? "default" : "primary"}
                onClick={() => setMessage(!message)}
              /> */}
              <FloatButton
                onClick={() => setOpenModal(!openModal)}
                icon={<SwapOutlined />}
              />
            </FloatButton.Group>
          </Fragment>
        )}
      </Drawer>
    </>
  );
};
export default PersonDetails;
