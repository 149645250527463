export function formatarDataHora(data) {
  // Convertendo a string da data para um objeto Date
  const dataObj = new Date(data);

  // Ajustando um horário padrão (por exemplo, 12:00)
  dataObj.setHours(12);
  dataObj.setMinutes(0);

  // Obtendo a hora atual para comparar
  const horaAtual = new Date();

  // Comparando para determinar se é de manhã, tarde, noite ou madrugada
  let periodo;
  if (dataObj.getHours() >= 5 && dataObj.getHours() < 12) {
    periodo = "da manhã";
  } else if (dataObj.getHours() >= 12 && dataObj.getHours() < 18) {
    periodo = "da tarde";
  } else if (dataObj.getHours() >= 18 && dataObj.getHours() < 24) {
    periodo = "da noite";
  } else {
    periodo = "da madrugada";
  }

  // Formatando a string de saída
  const dataFormatada = dataObj.toLocaleDateString("pt-BR");
  const horaFormatada = dataObj.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${dataFormatada} às ${horaFormatada} ${periodo}`;
}

export function obterData(data) {
    // Convertendo a string da data para um objeto Date
    const dataObj = new Date(data);

    // Zerando as horas, minutos, segundos e milissegundos
    dataObj.setHours(0, 0, 0, 0);

    // Formatando a string de saída
    const dataFormatada = dataObj.toLocaleDateString('pt-BR');

    return dataFormatada;
}
