import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Alert, DatePicker } from "antd";

import { Select, Space } from "antd";
import Upload from "../../components/upload";
import { useTranslation } from "react-i18next";

const DataDocument = ({ data }) => {
  const [docFront, setDocFront] = useState([]);
  const [docBack, setDocBack] = useState([]);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    if (docFront.length > 0 && docBack.length > 0) {
      values.documentfront = docFront;
      values.documentback = docBack;

      console.log("Success:", values);

      data(values);
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);

    console.log(docFront, docBack);
  };

  return (
    <Form
      name="basic"
      // labelCol={{
      //   span: 8,
      // }}
      // wrapperCol={{
      //   span: 16,
      // }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={t("Tipodedocumento")}
        name="documenttype"
        rules={[
          {
            required: true,
            message: t("Selecioneumtipodedocumento!"),
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          onChange={handleChange}
          defaultValue={""}
          options={[
            { value: "", label: t("Selecioneumtipodedocumento!") },
            { value: "BI", label: t("Bilhetedeindentidade") },
            { value: "Passport", label: t("Passaporte") },
          ]}
        />
      </Form.Item>

      <Form.Item
        label={`${t("Numerodedocumento")}`}
        name="docNumber"
        rules={[
          {
            required: true,
            message: "Please input your document number!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t("Datadeexpiracao")}`}
        name="expireDate"
        rules={[
          {
            required: true,
            message: "Please input expire date!",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label={`${t("Fontododocumentofrontal")}`} name="documentfront">
        <Upload setFile={setDocFront} />
      </Form.Item>

      <Form.Item label={`${t("Fontododocumentotraseira")}`} name="documentback">
        <Upload setFile={setDocBack} />
      </Form.Item>

      <Form.Item>
        {/* <Link to="/home"> */}
        {error ? (
          <Alert
            style={{ marginBottom: 15 }}
            message="Faca o upload das imagens do documento"
            type="error"
          />
        ) : (
          <></>
        )}
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", height: "42px" }}
          onClick={(e) => console.log(e)}
        >
          {t("Proximo")}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default DataDocument;
