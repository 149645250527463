import React, { useEffect, useState } from "react";
import { Button, Divider, message, Steps, theme } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import DateForm from "./dateForm";
import DataDocument from "./DataDocument";
import Camera from "../../components/camera";
import RegisterDone from "../../components/done";
import { useTranslation } from "react-i18next";

const Register = ({ setRegisterStep }) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [personData, setPersonData] = useState();
  const [documentData, setDocumentData] = useState();
  const [face, setFace] = useState();
  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log("dataaa", personData);

    if (personData != undefined) {
      setCurrent(1);
    }
  }, [personData]);

  useEffect(() => {
    console.log("dataaa", documentData);

    if (documentData != undefined) {
      setCurrent(2);
    }
  }, [documentData]);

  useEffect(() => {
    console.log("dataaa", face);

    if (face != undefined) {
      setCurrent(3);
    }
  }, [face]);

  const steps = [
    {
      title: t("Dados"),
      content: <DateForm data={setPersonData} />,
    },
    {
      title: "Docs",
      content: <DataDocument data={setDocumentData} />,
    },
    // {
    //   title: "Plan",
    //   content: <DataDocument data={setDocumentData} />,
    // },
    {
      title: "Face",
      content: <Camera data={setFace} />,
    },
    {
      title: t("Registro"),
      content: (
        <RegisterDone
          person={personData}
          document={documentData}
          faceData={face}
          setLoad={setLoader}
        />
      ),
    },
  ];

  const next = () => {
    setStatus(!true);
    // setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 20,
  };

  return (
    <div style={{ margin: "auto", width: "71%" }}>
      <Title level={3}>{t("Registre-separaFazeraDiferenca")}</Title>
      <Title level={5} style={{ opacity: 0.6, marginBottom: 60 }}>
        {t(
          "SejaPartedaComunidadedeBuscaCadastre-seAgoraeAjude-nosaReunirFamílias"
        )}
      </Title>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && <></>}
        {/* {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )} */}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
            disabled={loader}
          >
            Anterior
          </Button>
        )}

        <Divider>Or</Divider>

        {/* <Button
          //   type="primary"
          icon={<GoogleOutlined />}
          style={{ width: "100%", height: "42px", marginBottom: 15 }}
        >
          Iniciar sessao com Google
        </Button> */}

        <Button
          type="text"
          style={{ fontWeight: 600, opacity: 0.8 }}
          onClick={() => setRegisterStep(false)}
        >
          Ja tenho uma conta.
        </Button>
      </div>
    </div>
  );
};
export default Register;
