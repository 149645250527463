import api from "../axio/http";
import { getToken } from "../utils/auth";
import { decodeToken } from "../utils/decodeToken";

export async function ScaFace(formData) {
  try {
    const token = getToken();

    const decoded = decodeToken(token);

    const scan = await api.post(`scanFace/${decoded?.id}`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return scan.data;
  } catch (error) {
    console.log(error);
  }
}
export async function ScaFaceMatch(formData) {
  try {
    const token = getToken();

    const scan = await api.post("rekogSingle", formData, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return scan.data;
  } catch (error) {
    console.log(error);
  }
}
