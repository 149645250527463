import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Uploads from "../upload";
import { Space, Typography } from "antd";
import { ScaFace, ScaFaceMatch } from "../../request/Face";
import SucessFace from "./sucess";
import { io } from "socket.io-client";
const { Text, Link } = Typography;

const MatchFace = ({ openModal, setOpenModal, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [receivedMessage, setReceivedMessage] = useState("");

  useEffect(() => {
    // Cria uma nova instância do cliente Socket.IO
    const socketInstance = io("http://localhost:3000"); // Substitua pela URL do seu servidor Socket.IO

    // Define os manipuladores de eventos
    socketInstance.on("connect", () => {
      console.log("Socket.IO connection established");
      setSocket(socketInstance);
    });

    socketInstance.on("message", (data) => {
      console.log("Received:", data);
      setReceivedMessage(data);
    });

    socketInstance.on("disconnect", () => {
      console.log("Socket.IO connection closed");
    });

    // Encerra a conexão quando o componente é desmontado
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  const sendMessage = () => {
    if (socket && message.trim() !== "") {
      socket.emit("sendMessage", message);
      setMessage("");
    }
  };

  useEffect(() => {
    setIsModalOpen(openModal);
  }, [openModal]);

  const handleOk = async () => {
    // setIsModalOpen(false);
    // setOpenModal(false);
    setConfirmLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", file[0].originFileObj);
      formData.append("userId", id);

      const data = await ScaFaceMatch(formData);

      setSucessMessage(data.message);

      setConfirmLoading(false);

      setIsModalOpen(false);

      setSucess(true);

      console.log(data);
    } catch (error) {
      setConfirmLoading(false);
      console.log(error);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOpenModal(false);
    sendMessage()
  };
  return (
    <>
      <SucessFace
        message={sucessMessage}
        openModal={sucess}
        closeModal={setSucess}
      />

      <Modal
        title="Reconhecimento Facial"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={"Fechar"}
        okText={"Procurar"}
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled: file.length == 0 ? true : false || confirmLoading,
        }}
      >
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <div style={{ marginBottom: 30 }}>
            <Text type="warning" style={{ fontWeight: 600 }}>
              Use uma imagem com uma boa resolução (qualidade) para que os
              detalhes do seu rosto sejam claramente visíveis.
            </Text>
          </div>

          <Uploads setFile={setFile} />
        </div>
      </Modal>
    </>
  );
};
export default MatchFace;
