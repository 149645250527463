import React from "react";
import { Button, Checkbox, Form, Input, DatePicker } from "antd";

import { Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";

const Description = ({ data }) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { t } = useTranslation();


  const onFinish = (values) => {
    console.log("Success:", values);
    data(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <Form
      name="basic"
      // labelCol={{
      //   span: 8,
      // }}
      // wrapperCol={{
      //   span: 16,
      // }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={t("Dataehorariodaultimavezquefoivista")}
        name="lastTimesee"
        rules={[
          {
            required: true,
            message: "Please select a date!",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} onChange={onChange} />
      </Form.Item>

      <Form.Item
        label={t("Enderecooulocalizacaoexataondeapessoafoivistapelaultimavez")}
        name="location"
        rules={[
          {
            required: true,
            message: "Please input the locate!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("Descricaofisica(marcasdistintivas,tatuagens,cicatrizes,etc.)")}
        name="description"
        rules={[
          {
            required: true,
            message: "Please input the locate!",
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        {/* <Link to="/home"> */}
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", height: "42px" }}
          onClick={(e) => console.log(e)}
        >
          {t("Proximo")}
        </Button>
        {/* </Link> */}
      </Form.Item>
    </Form>
  );
};
export default Description;
