import React, { useEffect } from "react";
import { Button, Checkbox, DatePicker, Form, Input } from "antd";

import { Select, Space } from "antd";
import { useTranslation } from "react-i18next";

const DateForm = ({ data, status }) => {
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const {t} = useTranslation();

  const onFinish = (values) => {
    console.log("Success:", values);

    data(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      // labelCol={{
      //   span: 8,
      // }}
      // wrapperCol={{
      //   span: 16,
      // }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={`${t("NomeCompleto")}`}
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t("Email")}`}
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t("NumerodeTelemovel")}`}
        name="phone"
        rules={[
          {
            required: true,
            message: "Please input your phone number!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t("DataDeNascimento")}`}
        name="birthDay"
        rules={[
          {
            required: true,
            message: "Please input your birth day!",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={`${t("Genero")}`}
        name="gender"
        rules={[
          {
            required: true,
            message: "Please select your gender!",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          onChange={handleChange}
          options={[
            { value: "", label: "Select your gender" },
            { value: "masculine", label: t("Masculino") },
            { value: "feminine", label: t("Feminino") },
          ]}
        />
      </Form.Item>

      <Form.Item
        label={`${t("Senha")}`}
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", height: "42px" }}
          onClick={(e) => console.log(e)}
        >
        {t("Proximo")}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default DateForm;
