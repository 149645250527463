import React, { useState, useEffect, Fragment } from "react";
import Masonry from "react-masonry-css"; // Certifique-se de instalar essa biblioteca: npm install react-masonry-css
import "./ImageGrid.css"; // Crie um arquivo CSS para estilização
import PersonDetails from "../drawer/PersonDetails";
import { Skeleton } from "antd";
import { DotChartOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { getPeopleDetails } from "../../request/People";

const breakpointColumnsObj = {
  default: 4, // Número de colunas padrão
  1100: 4, // Se a largura da tela for menor que 1100px, use 2 colunas
  700: 2, // Se a largura da tela for menor que 700px, use 1 coluna
};

const ImageGrid = ({ images, people }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [active, setActive] = useState(true);
  const [selected, setSelected] = useState();

  useEffect(() => {
    setTimeout(() => {
      setActive(false);
    }, 5000);
  }, []);

  const onChange = async (select) => {
    setOpenDetails(!openDetails);

    const response = await getPeopleDetails(select);

    console.log(response);

    setSelected(response);
  };

  console.log("selectedselectedselected", selected)


  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      <PersonDetails
        openDrawer={openDetails}
        setcloseDrawer={setOpenDetails}
        data={selected}
        setSelected={setSelected}
      />
      {people?.map((image, index) => (
        <Fragment>
          {console.log(index)}
          {active ? (
            <Skeleton.Node
              active={true}
              style={{ width: "100%", height: "70vh", marginBottom: 30 }}
            >
              <DotChartOutlined
                style={{
                  fontSize: 40,
                  color: "#bfbfbf",
                  width: "100%",
                }}
              />
            </Skeleton.Node>
          ) : (
            <div
              onClick={() => onChange(image.id)}
              key={index}
              className="image-item"
            >
              <img src={image.image} alt={`Image ${index}`} />

              <div className="image-item-name">
                <Title level={3} style={{ color: "white", fontWeight: "bold" }}>
                  {image.name}
                </Title>
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </Masonry>
  );
};

export default ImageGrid;
