import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Pagination } from "swiper/modules";
import Title from "antd/es/typography/Title";

export default function Carousel() {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="https://cdn.pfizer.com/pfizercom/GettyImages-1182075825.jpg?iARm9oA7EzjjpFnyaC2i46gEJ.Vmu7Gp" />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "initial",
              left: 33,
            }}
          >
            <img style={{ width: "37px" }} src="./assets/icons/feedback.png" />
            <Title level={3} style={{ color: "whitesmoke" }}>
              lorem Ipsum
            </Title>
            <Title level={5} style={{ opacity: 0.8, color: "whitesmoke" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </Title>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <Title level={1} style={{ color: "whitesmoke", fontSize: 15 }}>
                Alberto Armando Muchanga
              </Title>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.jimmynelson.com/wp-content/uploads/2022/08/IX_141_Maori__Rauwhiri_Winitana_Paki___Taupo_Village__North_Islands___New_Zealand__2011-1-819x1024.jpg" />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "initial",
              left: 33,
            }}
          >
            <img style={{ width: "37px" }} src="./assets/icons/feedback.png" />
            <Title level={3} style={{ color: "whitesmoke" }}>
              lorem Ipsum
            </Title>
            <Title level={5} style={{ opacity: 0.8, color: "whitesmoke" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </Title>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <Title level={1} style={{ color: "whitesmoke", fontSize: 15 }}>
                Dell Corp
              </Title>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://hips.hearstapps.com/hmg-prod/images/you-people-unit-042-13191-r-jpg-1675191276.jpg?crop=0.888888888888889xw:1xh;center,top&resize=1200:*" />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "initial",
              left: 33,
            }}
          >
            <img style={{ width: "37px" }} src="./assets/icons/feedback.png" />
            <Title level={3} style={{ color: "whitesmoke" }}>
              lorem Ipsum
            </Title>
            <Title level={5} style={{ opacity: 0.8, color: "whitesmoke" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </Title>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <Title level={1} style={{ color: "whitesmoke", fontSize: 15 }}>
                Gina Ferreira
              </Title>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
