import api from "../axio/http";
import { getToken } from "../utils/auth";
import { decodeToken } from "../utils/decodeToken";

export async function QueueList() {
  try {
    const token = getToken();

    const decoded = decodeToken(token);

    const queue = await api.get(`queue/${decoded.id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return queue.data.data;
  } catch (error) {
    console.log(error);
  }
}
