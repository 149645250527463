import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Auth/login";
import { StyleProvider } from "@ant-design/cssinjs";
import Route from "./routes";
import './utils/i18n'


import buffer from 'buffer';
import ImageUploader from "./imageBack";
global.Buffer = buffer.Buffer; // Adiciona o polyfill globalmente


function App() {
  return (
    <StyleProvider hashPriority="high">
      <Route/>
    </StyleProvider>
  );
}

export default App;
