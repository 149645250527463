import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Col, Row, Statistic } from "antd";
import { useTranslation } from "react-i18next";
const formatter = (value) => <CountUp end={value} separator="," />;
const Balance = () => {
  const [count, setCount] = useState(995);

  const [effectSatus, setEffectStatus] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setEffectStatus(true);
    const intervalId = setInterval(() => {
      // Ação que será executada a cada intervalo
      setCount((prevCount) => prevCount + 1);
      setEffectStatus(false);
    }, 8000); // Intervalo de 1000 milissegundos (1 segundo)

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Row
      style={{
        alignItems: "center",
        width: "250px",
        fontWeight: 500,
        display: "contents",
      }}
    >
      <Col span={12}>
        <Statistic
          title={t("PublicacoesDiarias")}
          value={count}
          formatter={effectSatus ? formatter : effectSatus}
          style={{ textAlign: "center" }}
        />
      </Col>
    </Row>
  );
};
export default Balance;
