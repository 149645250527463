import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Login from "../pages/Auth/login";
import Home from "../pages/home";
import Princing from "../pages/princing";
import Payment from "../pages/teste";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/princing" element={<Princing />} />
      </Routes>
    </Router>
  );
}

export default App;
