import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Space, Tooltip, Dropdown, Badge } from "antd";
import Title from "antd/es/typography/Title";
import {
  SwapOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  BookOutlined,
  MessageOutlined,
  LoadingOutlined,
  Loading3QuartersOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { Select } from "antd";
import ImageGrid from "../components/mansoryList/ImageGrid ";
import PersonRegister from "../components/drawer/personRegister";
import Notifications from "../components/drawer/notifications";
import Saved from "../components/drawer/saved";
import Message from "../components/drawer/message";
import Settings from "../components/drawer/setting";
import ScanFace from "../components/modal/searchFace";
import { getToken, logout } from "../utils/auth";
import { decodeToken } from "../utils/decodeToken";
import { getPeople } from "../request/People";
import { io } from "socket.io-client";
import { QueueList } from "../request/Queue";
import Balance from "../components/balance";
import Profile from "../components/drawer/profile";
import { useTranslation } from "react-i18next";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { Header, Footer, Sider, Content } = Layout;
const headerStyle = {
  //   textAlign: "center",
  color: "#fff",
  //   height: 130,
  paddingInline: 50,
  lineHeight: "64px",
  backgroundColor: "white",
};
const contentStyle = {
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "white",
  height: "82vh",
  paddingRight: "50px",
  paddingLeft: "50px",
};
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#3ba0e9",
};
const footerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "white",
  width: "300px",
  margin: "auto",
  borderRadius: "64px",
  transform: "translate(0px, -10px)",
  boxShadow: "1px 2px 9px #e2f3f5",
  padding: "16px",
};

const Home = () => {
  const [open, setOpen] = useState(false);
  const [notify, setOpenNotify] = useState(false);
  const [profile, setProfile] = useState(false);
  const [saved, setOpenSaved] = useState(false);
  const [message, setOpenMessage] = useState(false);
  const [setting, setOpenSetting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState();
  const [peopleData, setPeople] = useState([]);
  const [queueData, setQueueList] = useState([]);
  const [socket, setSocket] = useState(null);
  const [messages, setMessage] = useState("");
  const [receivedMessage, setReceivedMessage] = useState("");
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState("");
  const [language, setLanguage] = useState("pt");

  let storege = localStorage.getItem("language");

  useEffect(() => {
    // Cria uma nova instância do cliente Socket.IO
    const socketInstance = io("http://localhost:3000"); // Substitua pela URL do seu servidor Socket.IO

    // Define os manipuladores de eventos
    socketInstance.on("connect", () => {
      console.log("Socket.IO connection established");
      setSocket(socketInstance);
    });

    socketInstance.on("list message", (data) => {
      console.log("Received:", data);
      setReceivedMessage(data);
    });

    socketInstance.on("disconnect", () => {
      console.log("Socket.IO connection closed");
    });

    if (storege != undefined) {
      setLanguage(localStorage.getItem("language"));
    }

    // Encerra a conexão quando o componente é desmontado
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // Verificar a autenticação ao carregar a página do painel
    const token = getToken();

    const decoded = decodeToken(token);

    setUser({
      name: decoded?.username,
      image: decoded?.image,
      id: decoded?.id,
    });

    if (!token) {
      window.location.href = "/";
    }

    people();
    queueList();
  }, []);

  useEffect(() => {
    if (search == "") {
      setSearchLoader(true);
      people();
      // setSearchLoader(false);
    }
  }, [search]);

  const people = async () => {
    try {
      const response = await getPeople();

      setPeople(response);

      setSearchLoader(false);

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const Search = async () => {
    setSearchLoader(true);
    try {
      const response = await getPeople(search);

      setPeople(response);

      setSearchLoader(false);

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const queueList = async () => {
    try {
      const response = await QueueList();

      setQueueList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          // target="_blank"
          rel="noopener noreferrer"
          // href="https://www.antgroup.com"
          onClick={() => setProfile(!profile)}
        >
          {t("Perfil")}
        </a>
      ),
    },
    // x
    {
      key: "3",
      label: (
        <a
          // target="_blank"
          rel="noopener noreferrer"
          // href="https://www.luohanacademy.com"
          onClick={() => handleLogout()}
        >
          {t("TerminarSessao")}
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <div
          // tdivrget="_blank"
          rel="noopener noreferrer"
          // href="https://www.luohanacademy.com"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              border: 0,
              background: language === "pt" ? "brown" : "",
              color: language === "pt" ? "white" : "grey",
              padding: "4px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("language", "pt");
              setLanguage(localStorage.getItem("language"));
              changeLanguage("pt");
            }}
          >
            PT
          </button>
          <button
            style={{
              border: 0,
              color: language === "en" ? "white" : "grey",
              padding: "4px",
              borderRadius: "4px",
              background: language === "en" ? "brown" : "",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("language", "en");
              setLanguage(localStorage.getItem("language"));
              changeLanguage("en");
            }}
          >
            EN
          </button>
        </div>
      ),
    },
  ];

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size={[0, 48]}
    >
      <Settings openDrawer={setting} setcloseDrawer={setOpenSetting} />
      <Message openDrawer={message} setcloseDrawer={setOpenMessage} />
      <Saved
        openDrawer={saved}
        setcloseDrawer={setOpenSaved}
        data={queueData}
      />
      <Notifications openDrawer={notify} setcloseDrawer={setOpenNotify} />
      <Profile openDrawer={profile} setcloseDrawer={setProfile} />
      <PersonRegister openDrawer={open} setcloseDrawer={setOpen} user={user} />
      <ScanFace openModal={openModal} setOpenModal={setOpenModal} />
      <Layout style={{ background: "white" }}>
        <Header style={headerStyle}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img width={"67px"} src="./assets/logo.png" />

            <Balance />

            <div style={{ display: "flex" }}>
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => setOpenNotify(!notify)}
              >
                <img
                  width={"35px"}
                  style={{ marginRight: "17px" }}
                  src="./assets/icons/icons8-notifications-64.png"
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Dropdown menu={{ items }} placement="bottom">
                  <img
                    style={{
                      width: "40px",
                      borderRadius: "63px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                    src={`${user?.image}`}
                  />
                </Dropdown>
              </div>
            </div>
          </div>
        </Header>
        <Content style={contentStyle}>
          <Title style={{ marginBottom: "10px" }}>{t("Explorar")}</Title>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "60px",
            }}
          >
            <Space wrap style={{ lineHeight: 0 }}>
              <Button
                style={{ height: "41px", borderRadius: 50 }}
                type="primary"
              >
                {t("Parati")}
              </Button>
              <Button style={{ height: "41px", borderRadius: 50 }}>
                {t("Destaques")}
              </Button>
              <Button
                style={{ height: "41px", borderRadius: 50 }}
                type="dashed"
              >
                {t("Novos")}
              </Button>
            </Space>

            <div style={{ color: "black" }}>
              <Space wrap style={{ lineHeight: 0, alignItems: "baseline" }}>
                <Title level={5}>{t("Pesquisar")}</Title>
                <Input
                  value={search}
                  placeholder="Alberto Muchanga"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Tooltip title={t("Pesquisar")}>
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={searchLoader || search == ""}
                    icon={
                      searchLoader ? (
                        <LoadingOutlined color="warning" />
                      ) : (
                        <SearchOutlined />
                      )
                    }
                    onClick={() => Search()}
                  />
                </Tooltip>
              </Space>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <ImageGrid people={peopleData?.slice().reverse()} />
          </div>
        </Content>
        <div style={{ position: "fixed", width: "100%", bottom: 0 }}>
          <Footer style={footerStyle}>
            <Space direction="vertical">
              <Space wrap>
                <Tooltip title={t("ReconhecimentoFacial")}>
                  <Button
                    style={{ marginRight: 40 }}
                    type="primary"
                    shape="circle"
                    icon={<SwapOutlined />}
                    onClick={() => setOpenModal(!openModal)}
                  />
                </Tooltip>

                <Tooltip title={t("Adicionar")}>
                  <Button
                    style={{ marginRight: 40 }}
                    type=""
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => setOpen(!open)}
                  />
                </Tooltip>

                {/* <Tooltip title="Messagens">
                  <Button
                    style={{ marginRight: 40 }}
                    type=""
                    shape="circle"
                    icon={<MessageOutlined />}
                    onClick={() => setOpenMessage(!message)}
                  />
                </Tooltip> */}

                <Tooltip title={t("Emandamento")}>
                  <Badge count={2}>
                    <Button
                      type=""
                      shape="circle"
                      // icon={<Loading3QuartersOutlined />}
                      icon={<LoadingOutlined color="warning" />}
                      onClick={() => setOpenSaved(!saved)}
                    />
                  </Badge>
                </Tooltip>
              </Space>
            </Space>
          </Footer>
        </div>
      </Layout>
    </Space>
  );
};
export default Home;
