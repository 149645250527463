import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Descriptions, Space, Image } from "antd";
import {
  UserOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const DocsUser = ({ openDrawer, setcloseDrawer, response }) => {
  const [open, setOpen] = useState(false);

  console.log("sfalksfm", response)

  const items = [
    {
      key: "3",
      label: "Documents image",
      children: (
        <Fragment>
          <Space>
            <Image
              width={150}
              src={response?.document[0]?.documentFrontPicture}
            />

            <Image
              width={150}
              src={response?.document[0]?.documentFrontPicture}
            />
          </Space>
        </Fragment>
      ),
    },
    {
      key: "1",
      label: "Expirate Date",
      children: response?.document[0]?.expirateDate,
    },
    {
      key: "2",
      label: "Type",
      children: response?.document[0]?.type,
    },
    {
      key: "8",
      label: "Document Number",
      children: response?.document[0]?.documentNumber,
    },
  ];

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);
  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  return (
    <>
      <Drawer
        title="Documents Information"
        onClose={onClose}
        open={open}
        style={{ position: "relative" }}
      >
        {/* <div style={{ textAlign: "center" }}>
          <Avatar size={100} icon={<UserOutlined />} />
        </div> */}

        <Descriptions
          layout="vertical"
          column={{ lg: 1, md: 1, sm: 1, xl: 1, xs: 1, xxl: 1 }}
          //   title="User Info"
          items={items}
        />
        <div style={{ position: "absolute", width: "90%", bottom: "10px" }}>
          <Button
            type="dashed"
            icon={<CloseCircleOutlined />}
            size={100}
            style={{ width: "100%" }}
            onClick={() => setcloseDrawer(false)}
          >
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};
export default DocsUser;
