import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, Alert } from "antd";
import { useTranslation } from "react-i18next";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const MultiUpload = ({ data }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const { t } = useTranslation();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setError(false);
    }
  }, [fileList]);

  const Submit = () => {
    if (fileList.length  <= 0) {
      console.log(fileList.length)
      setError(true);
    } else {
      data(fileList);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <Alert
        style={{ marginBottom: 15 }}
        message={t("DestaqueImage")}
        type="warning"
      />

      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        style={{ lineHeight: 0 }}
      >
        {fileList.length >= 5 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>

      {error ? (
        <Alert
          style={{ marginBottom: 15 }}
          message={t("Facaouploadcompelomenos3imagens")}
          type="error"
        />
      ) : (
        <></>
      )}

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: "100%", height: "42px" }}
        onClick={(e) => Submit()}
      >
        {t("Proximo")}
      </Button>
    </>
  );
};
export default MultiUpload;
