import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Card } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

// import './css.css'

const Notifications = ({ openDrawer, setcloseDrawer }) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);
  
  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title="Notificações"
        placement="right"
        onClose={onClose}
        open={open}
        // width={"23%"}
      >
        <Card style={{ width: "100%", marginBottom: 30 }}>
          <Meta
            avatar={<SettingOutlined />}
            title="Atualizacao dos termos e condicoes"
            description="This is the description"
          />
        </Card>

        <Card style={{ width: "100%", marginBottom: 30 }}>
          <Meta
            avatar={<EllipsisOutlined />}
            title="Notify Text"
            description="This is the description"
          />
        </Card>
      </Drawer>
    </>
  );
};
export default Notifications;
