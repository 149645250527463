import axios from "axios";
import { getToken } from "../utils/auth";

const token = getToken();

const api = axios.create({
  baseURL: 'https://server-find.onrender.com',
});

if (token) {
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
}

api.interceptors.request.use((config) => {
  return config;
});

export default api;
