import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Card, Select, Input, Button } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

const { TextArea } = Input;

// import './css.css'

const ReportIssue = ({ openDrawer, setcloseDrawer }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title="Reportar erro"
        placement="right"
        onClose={onClose}
        open={open}
        // width={"23%"}
      >
        <Select
          defaultValue=""
          style={{
            width: "100%",
            marginBottom: 20,
          }}
          onChange={handleChange}
          options={[
            {
              value: "",
              label: "Selecione o erro que ocorreu",
            },
            {
              value: "message",
              label: "Erro ao retornar dados da pessoa desaparecida",
            },
            {
              value: "scan",
              label: "Erro ao fazer o reconhecimento facial",
            },
            {
              value: "other",
              label: "Outro",
            },
          ]}
        />

        <TextArea rows={6} style={{ marginBottom: 20 }} />

        <Button style={{marginBottom: 20, width: "100%"}} type="primary">Enviar</Button>
      </Drawer>
    </>
  );
};
export default ReportIssue;
