import React, { useEffect, useState } from "react";
import { Avatar, Button, Drawer, Descriptions, Image } from "antd";
import { UserOutlined, DownloadOutlined } from "@ant-design/icons";
import DocsUser from "./docsUser";
import api from "../../axio/http";
import { getToken } from "../../utils/auth";
import { decodeToken } from "../../utils/decodeToken";

const Profile = ({ openDrawer, setcloseDrawer }) => {
  const [open, setOpen] = useState(false);
  const [docDrawer, setOpenDocDrawer] = useState(false);
  const [response, setResponse] = useState();

  const items = [
    {
      key: "1",
      label: "UserName",
      children: response?.name,
    },
    {
      key: "2",
      label: "Telephone",
      children: response?.phoneNumber,
    },
    {
      key: "6",
      label: "Email",
      children: response?.email,
    },
    {
      key: "3",
      label: "Gender",
      children: response?.gender,
    },
    {
      key: "4",
      label: "Location",
      children: response?.location,
    },
    {
      key: "5",
      label: "Birth Date",
      children: response?.birthDate,
    },
  ];

  useEffect(() => {
    setOpen(openDrawer);

    const token = getToken();

    const decoded = decodeToken(token);

    async function fetchData() {
      const response = await api.get(`getUserInfo/${decoded?.id}`);

      setResponse(response?.data?.data);
    }

    fetchData();
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  return (
    <>
      <DocsUser
        openDrawer={docDrawer}
        setcloseDrawer={setOpenDocDrawer}
        response={response}
      />
      <Drawer
        title="User Information"
        onClose={onClose}
        open={open}
        style={{ position: "relative" }}
      >
        <div style={{ textAlign: "center" }}>
          <Image
            width={100}
            height={100}
            src={`${response?.image}`}
            style={{ borderRadius: 50, objectFit: 'cover' }}
          />
        </div>

        <Descriptions
          layout="vertical"
          column={{ lg: 1, md: 1, sm: 1, xl: 1, xs: 1, xxl: 1 }}
          title="User Info"
          items={items}
        />
        <div style={{ position: "absolute", width: "90%", bottom: "10px" }}>
          <Button
            type="dashed"
            icon={<DownloadOutlined />}
            size={100}
            style={{ width: "100%" }}
            onClick={() => setOpenDocDrawer(!docDrawer)}
          >
            See Documents Details
          </Button>
        </div>
      </Drawer>
    </>
  );
};
export default Profile;
