import { Button, Divider } from "antd";
import { Fragment } from "react";
import {
  SearchOutlined,
  LoginOutlined,
  PoweroffOutlined,
  SendOutlined,
} from "@ant-design/icons";

const Princing = () => {
  return (
    <Fragment>
      <div
        style={{
          height: "100vh",
          display: "grid",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div id="card1">
            <h1
              style={{
                marginBottom: "38px",
                fontSize: "large",
                color: "#1677ff",
              }}
            >
              BASIC
            </h1>
            <div>
              <h1 style={{ fontSize: "xxx-large", marginBottom: 0 }}>FREE</h1>
              <h3 style={{ opacity: 0.6, marginTop: 0, marginBottom: 40 }}>
                And it will always be
              </h3>
              <h1 style={{ fontSize: "medium" }}>Basic Content to use like</h1>
              <ul
                className="ulModified"
                style={{ lineHeight: 2.5, fontWeight: 500 }}
              >
                <li>Até 1 publicações diárias.</li>
                <li>Cadastro Simplificado</li>
                <li>Compartilhamento em Redes Sociais</li>
                <li>Notificações de Correspondência (Somente por email)</li>
                <li>Alertas básicos sobre possíveis correspondências</li>
              </ul>
            </div>

            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  width: "100%",
                  height: 45,
                  fontWeight: 700,
                  background: "#3f3b3b",
                }}
                type="primary"
                icon={<LoginOutlined />}
              >
                SIGN IN
              </Button>
            </div>
          </div>

          <Divider type="vertical" style={{ height: 397, marginTop: 128 }} />

          <div id="card1">
            <h1
              style={{
                marginBottom: "38px",
                fontSize: "large",
                color: "#1677ff",
              }}
            >
              STANDARD
            </h1>
            <div>
              <h1 style={{ fontSize: "xxx-large", marginBottom: 0 }}>$40</h1>
              <h3 style={{ opacity: 0.6, marginTop: 0, marginBottom: 40 }}>
                And it will always be
              </h3>
              <h1 style={{ fontSize: "medium" }}>Standard Content to use like</h1>
              <ul
                className="ulModified"
                style={{ lineHeight: 2.5, fontWeight: 500 }}
              >
                <li>Até 5 publicações diárias</li>
                <li>Compartilhamento em Redes Sociais</li>
                <li>Notificações de Correspondência (Email & SMS)</li>
                <li>Alertas normais sobre possíveis correspondências</li>
                <li>Reconhecimento Facial Básico</li>
                <li>Geolocalização Histórica</li>
                <li>Integração com Órgãos Oficiais</li>
              </ul>
            </div>

            <div style={{ marginTop: 10 }}>
              <Button
                style={{ width: "100%", height: 45, fontWeight: 700 }}
                type="primary"
                icon={<PoweroffOutlined />}
              >
                Try it for 7 days
              </Button>
            </div>
          </div>

          <Divider type="vertical" style={{ height: 500, marginTop: 128 }} />

          <div id="card1">
            <h1
              style={{
                marginBottom: "38px",
                fontSize: "large",
                color: "#1677ff",
              }}
            >
              ULTIMATE
            </h1>
            <div>
              <h1 style={{ fontSize: "xxx-large", marginBottom: 0 }}>$120</h1>
              <h3 style={{ opacity: 0.6, marginTop: 0, marginBottom: 40 }}>
                And it will always be
              </h3>
              <h1 style={{ fontSize: "medium" }}>Ultimate Content to use like</h1>
              <ul
                className="ulModified"
                style={{ lineHeight: 2.5, fontWeight: 500 }}
              >
                <li>Até 50 publicações diárias</li>
                <li>Compartilhamento em Redes Sociais</li>
                <li>Notificações de Correspondência (Email & SMS)</li>
                <li>Alertas avancadas sobre possíveis correspondências</li>
                <li>Reconhecimento Facial Avançado</li>
                <li>Geolocalização Histórica</li>
                <li>Integração com Órgãos Oficiais</li>
                <li>Análise de Mídia Social</li>
                <li>Assistência Jurídica</li>
              </ul>
            </div>

            <div style={{ marginTop: 10 }}>
              <Button
                style={{ width: "100%", height: 45, background: "#3f3b3b" }}
                type="primary"
                icon={<SendOutlined />}
              >
                Try it for 7 days
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Princing;
