import React, { useEffect, useState } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import api from "../axio/http";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const RegisterDone = ({ person, document, faceData, setLoad }) => {
  const [loader, setLoader] = useState(null);

  const { t } = useTranslation();


  useEffect(() => {
    Register();
  }, []);

  const Register = async () => {
    setLoader(null);
    setLoad(true);

    const formData = new FormData();
    formData.append("image", faceData);
    formData.append("name", person.username);
    formData.append("email", person.email); // Preencha com o valor correto
    formData.append("birthDate", new Date(person.birthDay)); // Preencha com o valor correto
    formData.append("gender", person.gender); // Preencha com o valor correto
    formData.append("password", person.password); // Preencha com o valor correto
    formData.append("documentType", document.documenttype); // Preencha com o valor correto
    formData.append("image", document.documentback[0].originFileObj); // Preencha com o valor correto
    formData.append("image", document.documentfront[0].originFileObj); // Preencha com o valor correto
    formData.append("expirateDate", ""); // Preencha com o valor correto
    formData.append("phoneNumber", person.phone); // Preencha com o valor correto
    formData.append("documentNumber", document.docNumber); // Preencha com o valor correto
    formData.append("expirateDate", new Date(document.expireDate)); // Preencha com o valor correto

    try {
      await api.post("registerUser", formData);

      setLoader("sucess");
      setLoad(true);
    } catch (error) {
      console.log(error);

      setLoader("error");
      setLoad(false);
    }
  };

  return loader == "sucess" ? (
    <Result
      icon={<SmileOutlined />}
      title={t("sucessRegisterUser")}
      extra={
        <Button type="primary" onClick={() => (window.location.href = "/")}>
          {t("Entrar")}
        </Button>
      }
      style={{ lineHeight: 0 }}
    />
  ) : loader == "error" ? (
    <Result
      status="warning"
      title={t("errorRegisterUser")}
      extra={
        <Button type="primary" key="console" onClick={() => Register()}>
          {t("TenteNovamente")}
        </Button>
      }
      style={{ lineHeight: 0 }}
    />
  ) : (
    <Spin size="large" />
  );
};
export default RegisterDone;
