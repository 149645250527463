import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Card, Switch, Col, Row } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

// import './css.css'

const NotificationsChanges = ({ openDrawer, setcloseDrawer }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title="Notificações"
        placement="right"
        onClose={onClose}
        open={open}
        // width={"23%"}
      >
        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes por email</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes por sms</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes de messagens recebidas</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 20,
            borderBottom: "3px solid whitesmoke",
            paddingBottom: "10px",
          }}
        >
          <Col span={20}>Receber Notificacoes</Col>
          <Col span={4}>
            <Switch defaultChecked onChange={onChange} />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
export default NotificationsChanges;
