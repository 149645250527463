import api from "../axio/http";
import { getToken } from "../utils/auth";

export async function getPeople(filter) {
  try {
    const token = getToken();

    const filte = filter === undefined ? "" : filter;

    const people = await api.get(`getPeople?name=${filte}`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return people.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getPeopleDetails(id) {
  try {
    const token = getToken();

    const people = await api.get(`getPeopleDetail/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return people.data.data;
  } catch (error) {
    console.log(error);
  }
}
