import React from "react";
import { Button, Checkbox, Form, Input, DatePicker } from "antd";

import { Select, Space } from "antd";
import { useTranslation } from "react-i18next";

const DateFormPerson = ({ data }) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);

    data(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <Form
      name="basic"
      // labelCol={{
      //   span: 8,
      // }}
      // wrapperCol={{
      //   span: 16,
      // }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={t("NomeCompleto")}
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("Genero")}
        name="gender"
        rules={[
          {
            required: true,
            message: "Please select your gender!",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          onChange={handleChange}
          options={[
            { value: "", label: "Select your gender" },
            { value: "Man", label: t("Masculino") },
            { value: "Male", label: t("Feminino") },
          ]}
        />
      </Form.Item>

      <Form.Item
        label={t("DataDeNascimento")}
        name="date"
        rules={[
          {
            required: true,
            message: "Please select birth date!",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} onChange={onChange} />
      </Form.Item>

      <Space>
        <Form.Item
        label={t("Cordosolhos")}
        name="eyes"
          rules={[
            {
              required: true,
              message: "Please input eyes color!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
        label={t("Altura")}
        name="height"
          rules={[
            {
              required: true,
              message: "Please input height!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
        label={t("Peso")}
        name="weight"
          rules={[
            {
              required: true,
              message: "Please input weight!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Space>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", height: "42px" }}
        >
        {t("Proximo")}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default DateFormPerson;
