import { Fragment, useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Tooltip,
  message,
  Spin,
} from "antd";
import Title from "antd/es/typography/Title";
import { GoogleOutlined } from "@ant-design/icons";
import Carousel from "../../components/carousel";
import Register from "./register";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../axio/http";
import { login } from "../../utils/auth";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { i18n } = useTranslation();

  const [registerStep, setRegisterStep] = useState(false);
  const [error, setError] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [state, setState] = useState({
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("statestatestate", state);

  useEffect(() => {
    getGeoInfo();
  }, []);

  const onFinish = async (values) => {
    console.log(values);

    try {
      setLoader(true);

      const response = await api.post("login", {
        phoneNumber: values?.phoneNumber,
        password: values?.password,
      });

      console.log(response.data.token);

      const fakeToken = response.data.token;

      login(fakeToken);

      window.location.href = "/home";
    } catch (error) {
      setLoader(false);

      if (error?.response?.data?.error) {
        messageApi.open({
          type: "error",
          content: `${error?.response?.data?.error}`,
        });

        return;
      }

      messageApi.open({
        type: "error",
        content: `Erro no servidor tente novamente`,
      });

      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      {contextHolder}
      <div style={mystyle.container}>
        <div style={mystyle.mystyle}>
          {registerStep == false ? (
            <div style={{ margin: "auto", width: "71%" }}>
              <Title>
                {state.countryName == "Mozambique"
                  ? t("ConectandoCoracoesEncontrandoEsperanca")
                  : t("Indisponibilidadedositenasuaregiao")}
              </Title>
              <Title
                level={5}
                style={{
                  opacity: 0.6,
                  marginBottom: 60,
                  textAlign:
                    state.countryName == "Mozambique" ? "center" : "justify",
                }}
              >
                {state.countryName == "Mozambique"
                  ? t(
                      "FacaoLoginparaRestaurarVinculosPerdidosJuntosEncontramosEsperanca"
                    )
                  : t("Indisponivel")}
              </Title>
              {state.countryName == "Mozambique" ? (
                <Fragment>
                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item
                      label={`${t("NumerodeTelemovel")}`}
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumber style={{ padding: 5, width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      label={`${t("Senha")}`}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password style={{ padding: 10 }} />
                    </Form.Item>

                    {/* <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item> */}

                    <Form.Item>
                      {/* <Link to="/home"> */}
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%", height: "42px" }}
                        disabled={loader}
                      >
                        {loader ? <Spin /> : t("Entrar")}
                      </Button>
                      {/* </Link> */}
                    </Form.Item>
                  </Form>
                  <Divider>{t("Or")}</Divider>
                  {/* <Button
                    icon={<GoogleOutlined />}
                    style={{ width: "100%", height: "42px", marginBottom: 15 }}
                    disabled={loader}
                  >
                    Iniciar sessao com Google
                  </Button> */}
                  <Button
                    onClick={() => setRegisterStep(true)}
                    type="text"
                    style={{ fontWeight: 600, opacity: 0.8 }}
                    disabled={loader}
                  >
                    {t("CriarConta")}
                  </Button>{" "}
                </Fragment>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <Register setRegisterStep={setRegisterStep} />
          )}
        </div>

        <div style={mystyle.mystyle2}>
          <div style={registerStep == true ? mystyle.register : mystyle.text}>
            <img style={mystyle.image} src="./assets/logo.png" />

            <Carousel />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mystyle = {
  container: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    // height: "97.7vh",
    alignItems: "center",
  },
  mystyle: {
    color: "white",
    // backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    width: "48%",
    borderRadius: 10,
    textAlign: "center",
  },

  mystyle2: {
    color: "white",
    // backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    width: "48%",
    height: "97%",
    borderRadius: 10,
    borderRadius: "10px",
    position: "relative",
  },
  image: {
    position: "absolute",
    top: 33,
    zIndex: 11,
    width: "70px",
    height: "70px",
    left: 33,
    borderRadius: 10,
  },
  register: {
    position: "fixed",
    bottom: "13px",
  },
};
