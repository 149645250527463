import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Card } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import NotificationsChanges from "./Settings/notificaitonChanges";
import ReportIssue from "./Settings/reportIssue";
import FAQ from "./Settings/faq";

const { Meta } = Card;

// import './css.css'

const Settings = ({ openDrawer, setcloseDrawer }) => {
  const [open, setOpen] = useState(false);
  const [notficationsChange, setOpenNotficationsChange] = useState(false);
  const [reportIssue, setOpenReportIssue] = useState(false);
  const [faq, setOpenFaq] = useState(false);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title="Definições"
        placement="right"
        onClose={onClose}
        open={open}
        // width={"23%"}
      >
        <NotificationsChanges
          openDrawer={notficationsChange}
          setcloseDrawer={setOpenNotficationsChange}
        />

        <ReportIssue
          openDrawer={reportIssue}
          setcloseDrawer={setOpenReportIssue}
        />

        <FAQ openDrawer={faq} setcloseDrawer={setOpenFaq} />

        <Card
          style={{ width: "100%", marginBottom: 30, cursor: "pointer" }}
          onClick={() => setOpenNotficationsChange(!notficationsChange)}
        >
          <Meta
            avatar={<SettingOutlined />}
            title="Notificacoes"
            description="Alteracoes Disponiveis"
          />
        </Card>

        <Card
          style={{ width: "100%", marginBottom: 30, cursor: "pointer" }}
          onClick={() => setOpenReportIssue(!reportIssue)}
        >
          <Meta
            avatar={<EllipsisOutlined />}
            title="Reportar um erro"
            description="Reporte um erro que ocorreu durante o uso do sistema"
          />
        </Card>

        <Card
          style={{ width: "100%", marginBottom: 30, cursor: "pointer" }}
          onClick={() => {
            setOpenFaq(!faq);
          }}
        >
          <Meta
            avatar={<EllipsisOutlined />}
            title="FAQ"
            description="Encontre aqui perguntas frequentes"
          />
        </Card>
      </Drawer>
    </>
  );
};
export default Settings;
