import Cookies from 'js-cookie';

// Função para realizar o login e armazenar o token nos cookies
export const login = (token) => {
  Cookies.set('token', token, { expires: 1 }); // Token expira em 7 dias
};

// Função para fazer logout e remover o token dos cookies
export const logout = () => {
  Cookies.remove('token');
};

// Função para pegar o token dos cookies
export const getToken = () => {
  return Cookies.get('token');
};
