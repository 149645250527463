import api from "../axio/http";
import { getToken } from "../utils/auth";

export async function Likes(userId, peopleId) {
  try {
    const token = getToken();

    const likes = await api.post(`likes/${userId}/${peopleId}`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return likes;
  } catch (error) {
    console.log(error);
  }
}
