import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import Register from "../../pages/Auth/register";
import RegisterPerson from "../../pages/Person/register";
import { useTranslation } from "react-i18next";

// import './style.css'

const PersonRegister = ({ openDrawer, setcloseDrawer, user }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title={t("Informacoespessoaisdapessoadesaparecida")}
        placement="right"
        onClose={onClose}
        open={open}
        width={"40%"}
      >
        <RegisterPerson user={user} setcloseDrawer={setcloseDrawer} />
      </Drawer>
    </>
  );
};
export default PersonRegister;
