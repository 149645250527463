import React, { useEffect, useState } from "react";
import {
  Drawer,
  Avatar,
  Card,
  Layout,
  Space,
  Col,
  Divider,
  List,
  Row,
  Input,
  Dropdown,
} from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UserOutlined,
  DownOutlined,
  SmileOutlined,
  MoreOutlined,
} from "@ant-design/icons";

const { Search } = Input;

const { Header, Footer, Sider, Content } = Layout;

const { Meta } = Card;

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
  backgroundColor: "#7dbcea",
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  height: "64.5vh",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#108ee9",
};
const siderStyle = {
  //   textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "white",
  width: "200px",
  // height: "40vh",
};
const footerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#7dbcea",
};

const Message = ({ openDrawer, setcloseDrawer }) => {
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const onSearch = (value) => console.log(value);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const onClose = () => {
    setOpen(false);
    setcloseDrawer(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerInfo = () => {
    setOpenInfo(true);
  };
  const onCloseInfo = () => {
    setOpenInfo(false);
  };

  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );

  const items = [
    {
      key: "1",
      label: (
        <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href="https://www.antgroup.com"
          onClick={showDrawerInfo}
        >
          Perfil
        </a>
      ),
    },
  ];

  return (
    <>
      {/* <Button type="primary" onClick={showDrawer}>
        Open
      </Button> */}
      <Drawer
        title="Messagens"
        placement="right"
        onClose={onClose}
        open={open}
        width={"400px"}
        height={"300px"}
        style={{ padding: 0 }}
        className="drawerMessage"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size={[0, 48]}
        >
          <Layout>
            <Sider style={siderStyle} width={"400px"}>
              <List
                dataSource={[
                  {
                    id: 1,
                    name: "Lily Fernandes",
                  },
                  {
                    id: 2,
                    name: "Bot Messeger",
                  },
                  {
                    id: 3,
                    name: "Support Messeger",
                  },
                ]}
                bordered
                style={{ borderRadius: 0, height: "30vh", overflow: 'auto' }}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    // actions={[
                    //   <a onClick={showDrawerInfo} key={`a-${item.id}`}>
                    //     View Profile
                    //   </a>,
                    // ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
                      }
                      title={
                        <a href="https://ant.design/index-cn">{item.name}</a>
                      }
                      description="Progresser XTech"
                    />
                  </List.Item>
                )}
              />
              <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={onCloseInfo}
                open={openInfo}
              >
                <p
                  className="site-description-item-profile-p"
                  style={{
                    marginBottom: 24,
                  }}
                >
                  User Profile
                </p>
                <p className="site-description-item-profile-p">Personal</p>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="Full Name" content="Lily" />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title="Account"
                      content="AntDesign@example.com"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="City" content="HangZhou" />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="Country" content="China🇨🇳" />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItem
                      title="Birthday"
                      content="February 2,1900"
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="Website" content="-" />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DescriptionItem
                      title="Message"
                      content="Make things as simple as possible but no simpler."
                    />
                  </Col>
                </Row>
                <Divider />
                <p className="site-description-item-profile-p">Company</p>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="Position" content="Programmer" />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title="Responsibilities"
                      content="Coding"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="Department" content="XTech" />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="Supervisor" content={<a>Lin</a>} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DescriptionItem
                      title="Skills"
                      content="C / C + +, data structures, software engineering, operating systems, computer networks, databases, compiler theory, computer architecture, Microcomputer Principle and Interface Technology, Computer English, Java, ASP, etc."
                    />
                  </Col>
                </Row>
                <Divider />
                <p className="site-description-item-profile-p">Contacts</p>
                <Row>
                  <Col span={12}>
                    <DescriptionItem
                      title="Email"
                      content="AntDesign@example.com"
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title="Phone Number"
                      content="+86 181 0000 0000"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DescriptionItem
                      title="Github"
                      content={
                        <a href="http://github.com/ant-design/ant-design/">
                          github.com/ant-design/ant-design/
                        </a>
                      }
                    />
                  </Col>
                </Row>
              </Drawer>

              <div style={{ height: "62vh", background: "whitesmoke" }}>
                <List
                  dataSource={[
                    {
                      id: 1,
                      name: "Lily",
                    },
                  ]}
                  // bordered
                  style={{
                    borderRadius: 0,
                    paddingInline: "24px",
                    background: "#1677ff",
                  }}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      actions={[
                        <Dropdown
                          menu={{
                            items,
                          }}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              <MoreOutlined style={{fontSize: "large"}}/>
                            </Space>
                          </a>
                        </Dropdown>,
                      ]}
                      className="listItemMessege"
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
                        }
                        title={
                          <a href="https://ant.design/index-cn">{item.name}</a>
                        }
                        description="Online"
                      />
                    </List.Item>
                  )}
                />

                <div className="chat">
                  <ul
                    style={{
                      lineHeight: "20px",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                    <li>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</li>
                    <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</li>
                    <li> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</li>
                    <li>There are many variations.</li>
                    <li>If you are going to use a passage of Lorem Ipsum, you need to be sure</li>
                  </ul>
                </div>

                <div className="textMessage">
                  <Search
                    placeholder="input search text"
                    onSearch={onSearch}
                    enterButton
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </div>
            </Sider>
            {/* <Layout>
              <Header style={headerStyle}>Header</Header>
              <Content style={contentStyle}>Content</Content>
              <Footer style={footerStyle}>
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  enterButton
                />
              </Footer>
            </Layout> */}
          </Layout>
        </Space>
      </Drawer>
    </>
  );
};
export default Message;
